.card_pointer {
  cursor: pointer;
}

.cardStyleNotAut {
  /*margin: 0 4rem 0 4rem;*/
  padding: 3rem;
  border-style: none;
  background-color: #c4cfda;
}

.cardStyleNotAut4 {
  /*margin: 0 4rem 0 4rem;*/
  padding: 3rem;
  border-style: none;
  background-color: #c4cfda;
}

.subFooterWrapper {
  background-color: #c4cfda;
  padding: 1rem;
}

.padding__unset {
  padding: unset;
}

a:visited {
  color: #54565a;
  text-decoration: underline !important;
}

.card_link {
  color: #54565a;
  text-decoration: none !important;
}

.t-stone .card {
  border: none !important;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .cardCol {
    padding: 1rem 0 2rem 0;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .cardCol {
    padding: 1rem 0 2rem 0;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .customButton {
    width: 5rem;
  }
  .cardStyleNotAut {
    /*margin: 0 4rem 0 4rem;*/
    padding: 3rem;
  }

  .cardStyleNotAut4 {
    /*margin: 0 4rem 0 4rem;*/
    padding: 1rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
